<template>
  <div>
    <table class="table table-google">
      <thead>
        <tr>
          <th colspan="2">Field</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>UUID</td>
          <td>{{ value.uuid }}</td>
        </tr>
        <tr>
          <td>Name</td>
          <td>
            <b-form-input
              v-model="value.field_name"
              type="text"
              autocomplete="off"
              size="sm"
              autofocus
              placeholder="field_name"
            ></b-form-input>
          </td>
        </tr>
        <tr>
          <td>Type</td>
          <td>
            <button-select
              v-model="value.field_type"
              :options="options.field_type"
              size="sm"
            />
          </td>
        </tr>
        <tr v-if="hasField('field_direction')">
          <td>{{ getFieldTitle('field_direction') }}</td>
          <td>
            <button-select
              v-model="value.field_direction"
              :options="options.field_direction"
              size="sm"
            />
          </td>
        </tr>
        <tr v-if="hasField('barcode_type')">
          <td>{{ getFieldTitle('barcode_type') }}</td>
          <td>
            <button-select
              v-model="value.barcode_type"
              :options="options.barcode_type"
              size="sm"
            />
          </td>
        </tr>
        <tr v-if="hasField('barcode_text')">
          <td>{{ getFieldTitle('barcode_text') }}</td>
          <td>
            <button-select
              v-model="value.barcode_text"
              :options="options.boolean"
              size="sm"
            />
          </td>
        </tr>
        <tr v-if="hasField('title')">
          <td>{{ getFieldTitle('title') }}</td>
          <td>
            <b-form-input
              v-model="value.title"
              type="text"
              autocomplete="off"
              size="sm"
            ></b-form-input>
          </td>
        </tr>
        <tr v-if="hasField('text')">
          <td>{{ getFieldTitle('text') }}</td>
          <td>
            <b-form-input
              v-model="value.text"
              type="text"
              autocomplete="off"
              size="sm"
            ></b-form-input>
          </td>
        </tr>
        <tr v-if="hasField('text_dots')">
          <td>{{ getFieldTitle('text_dots') }}</td>
          <td>
            <button-select
              v-model="value.text_dots"
              :options="options.boolean"
              size="sm"
            />
          </td>
        </tr>
        <tr v-if="hasField('text_title')">
          <td>{{ getFieldTitle('text_title') }}</td>
          <td>
            <button-select
              v-model="value.text_title"
              :options="options.boolean"
              size="sm"
            />
          </td>
        </tr>
        <tr v-if="hasField('x1')">
          <td>{{ getFieldTitle('x1') }}</td>
          <td>
            <number-field min="0" v-model="value.x1" />
          </td>
        </tr>
        <tr v-if="hasField('y1')">
          <td>{{ getFieldTitle('y1') }}</td>
          <td>
            <number-field min="0" v-model="value.y1" />
          </td>
        </tr>
        <tr v-if="hasField('x2')">
          <td>{{ getFieldTitle('x2') }}</td>
          <td>
            <number-field min="0" v-model="value.x2" />
          </td>
        </tr>
        <tr v-if="hasField('y2')">
          <td>{{ getFieldTitle('y2') }}</td>
          <td>
            <number-field min="0" v-model="value.y2" />
          </td>
        </tr>
        <tr v-if="hasField('thickness')">
          <td>{{ getFieldTitle('thickness') }}</td>
          <td>
            <number-field min="1" max="8" v-model="value.thickness" />
          </td>
        </tr>
        <tr v-if="hasField('font_size')">
          <td>{{ getFieldTitle('font_size') }}</td>
          <td>
            <number-field min="1" v-model="value.font_size" />
          </td>
        </tr>
        <tr v-if="hasField('font_bold')">
          <td>{{ getFieldTitle('font_bold') }}</td>
          <td>
            <button-select
              v-model="value.font_bold"
              :options="options.boolean"
              size="sm"
            />
          </td>
        </tr>
        <tr v-if="hasField('font_italic')">
          <td>{{ getFieldTitle('font_italic') }}</td>
          <td>
            <button-select
              v-model="value.font_italic"
              :options="options.boolean"
              size="sm"
            />
          </td>
        </tr>
        <tr v-if="hasField('font_underline')">
          <td>{{ getFieldTitle('font_underline') }}</td>
          <td>
            <button-select
              v-model="value.font_underline"
              :options="options.boolean"
              size="sm"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
const ButtonSelect = () => import('@/components/global/ButtonSelect.vue');
const NumberField = () => import('@/components/label_raw/NumberField.vue');

export default {
  name: 'RawField',
  components: {
    ButtonSelect,
    NumberField,
  },
  props: {
    value: Object,
  },
  data() {
    return {
      options: {
        field_type: [
          { text: 'Text', value: 'text' },
          { text: 'Rectangle', value: 'rectangle' },
          { text: 'Line', value: 'line' },
          { text: 'Barcode', value: 'barcode' },
        ],
        field_direction: [
          { text: 'Left-to-right', value: 'ltr' },
          { text: 'Center', value: 'center' },
          { text: 'Right-to-left', value: 'rtl' },
        ],
        boolean: [
          { text: 'Yes', value: true },
          { text: 'No', value: false },
        ],
        barcode_type: [
          { text: 'EAN-13', value: 7 },
          { text: 'QR Code', value: 23 },
        ],
      },
      fieldTypeFields: {
        default: {
          field_direction: 'Direction',
          title: 'Title',
          text: 'Text',
          text_dots: 'Show dots',
          text_title: 'Show title',
          x1: 'X1',
          x2: 'X2',
          y1: 'Y1',
          y2: 'Y2',
          thickness: 'Thickness',
          barcode_type: 'Barcode type',
          barcode_text: 'Barcode text',
          font_size: 'Font size',
          font_bold: 'Font bold',
          font_italic: 'Font italic',
          font_underline: 'Font underline',
        },
        text: {
          field_direction: '',
          text: '',
          x1: 'X',
          y1: 'Y',
          x2: 'Width',
          font_size: '',
          font_bold: '',
          font_italic: '',
          font_underline: '',
        },
        rectangle: {
          x1: 'X',
          y1: 'Y',
          x2: 'Width',
          y2: 'Height',
          thickness: '',
        },
        line: {
          x1: 'X1',
          y1: 'Y1',
          x2: 'X2',
          y2: 'Y2',
          thickness: '',
        },
        barcode: {
          text: '',
          x1: 'Left',
          y1: 'Top',
          x2: 'Right',
          y2: 'Bottom',
          barcode_type: '',
          barcode_text: '',
          font_size: '',
          font_bold: '',
          font_italic: '',
          font_underline: '',
        },
      },
    };
  },
  methods: {
    hasField(fieldName) {
      return fieldName in this.fieldTypeFields[this.value.field_type];
    },
    getFieldTitle(fieldName) {
      const customFieldTitle = this.fieldTypeFields[this.value.field_type][fieldName];
      return (
        customFieldTitle === undefined || customFieldTitle.length === 0
          ? this.fieldTypeFields.default[fieldName]
          : customFieldTitle
      );
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
